define("ui/components/notifier/notifier-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W7tUo6Yy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"bulkActions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\",true],[10,\"valign\",\"middle\"],[10,\"class\",\"row-check\"],[10,\"style\",\"padding-top: 2px;\"],[8],[0,\"\\n    \"],[1,[28,\"check-box\",null,[[\"nodeId\"],[[24,[\"model\",\"id\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"state\"]]]]],[10,\"class\",\"clip\"],[8],[0,\"\\n  \"],[1,[28,\"badge-state\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"name\"]]]]],[8],[0,\"\\n  \"],[1,[24,[\"model\",\"displayName\"]],false],[0,\"\\n   \"],[7,\"div\",true],[10,\"class\",\"text-muted text-small\"],[8],[1,[24,[\"model\",\"description\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"type\"]]]]],[10,\"class\",\"clip\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-capitalize\"],[8],[1,[24,[\"model\",\"notifierType\"]],false],[0,\" \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showNotifierValue\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"text-muted text-small\"],[8],[1,[24,[\"model\",\"notifierTableLabel\"]],false],[0,\": \"],[1,[24,[\"model\",\"notifierValue\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"created\"]]]]],[10,\"class\",\"text-right pr-20\"],[8],[0,\"\\n  \"],[1,[24,[\"model\",\"displayCreated\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[28,\"t\",[\"generic.actions\"],null]]]],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[1,[28,\"action-menu\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/notifier/notifier-row/template.hbs"
    }
  });

  _exports.default = _default;
});