define("ui/authenticated/project/help/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    scope: Ember.inject.service(),
    modelError: false,
    modelResolved: false,
    hasHosts: true,
    docsLink: Ember.computed.alias('settings.docsBase'),
    modelObserver: Ember.observer('model', function () {
      if (this.get('model.resolved')) {
        // @@TODO@@ - need to add some error handling
        this.set('modelResolved', true);
      }

      if (this.get('model.error')) {
        this.set('modelError', true);
      }
    }),
    latestAnnouncement: Ember.computed('model.announcements', function () {
      if (this.get('model.announcements.topics')) {
        var sorted = this.get('model.announcements.topics').sortBy('id');
        var announcement = sorted[sorted.length - 1];
        return {
          title: announcement.title,
          link: "".concat(this.get('forumsLink'), "/t/").concat(announcement.slug),
          created: announcement.created_at
        };
      }
    }),
    forumsLink: _constants.default.EXT_REFERENCES.FORUM,
    companyLink: _constants.default.EXT_REFERENCES.COMPANY,
    githubLink: _constants.default.EXT_REFERENCES.GITHUB
  });

  _exports.default = _default;
});