define("ui/components/settings/user-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AGdvVHtu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[1,[28,\"t\",[\"userPreferencesPage.generalInfo.header\"],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"box\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-12\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[\"userPreferencesPage.generalInfo.name.label\"],null],false],[9],[0,\"\\n      \"],[1,[28,\"input-or-display\",null,[[\"editable\",\"value\"],[[24,[\"editing\"]],[24,[\"account\",\"name\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-12\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[\"userPreferencesPage.generalInfo.username.label\"],null],false],[9],[0,\"\\n      \"],[1,[28,\"input-or-display\",null,[[\"editable\",\"value\"],[[24,[\"editing\"]],[24,[\"account\",\"login\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row text-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-12 text-left\"],[8],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"btn bg-primary\"],[3,\"action\",[[23,0,[]],\"editPassword\"]],[8],[1,[28,\"t\",[\"userPreferencesPage.generalInfo.password.text\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/settings/user-info/template.hbs"
    }
  });

  _exports.default = _default;
});