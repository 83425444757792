define("ui/components/settings/telemetry-opt/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W9NY6yLi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"telemetryOpt.header\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"text-muted\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"telemetryOpt.subtext\"],[[\"appName\",\"htmlSafe\"],[[24,[\"settings\",\"appName\"]],true]]],false],[0,\"\\n  \"],[7,\"a\",true],[11,\"href\",[29,[[24,[\"settings\",\"docsBase\"]],\"/faq/telemetry\"]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener nofollow\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"telemetryOpt.learnMore.label\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col-inline checkbox\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"optIn\"]]]]],false],[0,\"\\n    \"],[1,[28,\"t\",[\"telemetryOpt.label\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"save-cancel\",null,[[\"cancelDisabled\",\"editing\",\"save\"],[true,true,[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/settings/telemetry-opt/template.hbs"
    }
  });

  _exports.default = _default;
});