define("ui/components/container-dot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fyw9GKOG",
    "block": "{\"symbols\":[\"container\"],\"statements\":[[4,\"if\",[[24,[\"model\",\"hasSidekicks\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"dot-group bg-default hand\"],[8],[0,\"\\n\"],[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"placement\",\"tagName\",\"tooltipFor\"],[[24,[\"type\"]],[24,[\"model\"]],[24,[\"template\"]],\"top\",\"span\",\"containerDot\"]],{\"statements\":[[4,\"each\",[[24,[\"model\",\"containers\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\",true],[11,\"class\",[29,[[23,1,[\"stateIcon\"]],\" \",[23,1,[\"stateColor\"]],\" dot\"]]],[11,\"alt\",[22,\"alt\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"placement\",\"tagName\",\"tooltipFor\"],[[24,[\"type\"]],[24,[\"model\"]],[24,[\"template\"]],\"top\",\"span\",\"containerDot\"]],{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[[24,[\"model\",\"stateIcon\"]],\" \",[24,[\"model\",\"stateColor\"]],\" dot hand\"]]],[11,\"alt\",[22,\"alt\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/container-dot/template.hbs"
    }
  });

  _exports.default = _default;
});