define("ui/components/cluster/cis/scan/detail/nodes-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GyZR3WFw",
    "block": "{\"symbols\":[\"node\"],\"statements\":[[7,\"table\",true],[10,\"width\",\"100%\"],[10,\"class\",\"table small mb-10 fully-bordered solid\"],[8],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n        \"],[7,\"tr\",true],[10,\"class\",\"group-row\"],[8],[0,\"\\n            \"],[7,\"td\",true],[10,\"class\",\"pl-15\"],[10,\"colspan\",\"2\"],[8],[1,[28,\"t\",[\"cis.scan.detail.table.nodesTable.title\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"nodes\"]]],null,{\"statements\":[[0,\"            \"],[5,\"cluster/cis/scan/detail/nodes-table/table-row\",[],[[\"@model\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/cluster/cis/scan/detail/nodes-table/template.hbs"
    }
  });

  _exports.default = _default;
});