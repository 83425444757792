define("ui/components/form-env-var/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FCSr6KC4",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"label\"],\"statements\":[[4,\"sortable-table\",null,[[\"classNames\",\"bulkActions\",\"rowActions\",\"paging\",\"search\",\"sortBy\",\"stickyHeader\",\"descending\",\"headers\",\"body\"],[\"grid fixed mb-0 sortable-table\",false,false,false,true,[24,[\"sortBy\"]],false,[24,[\"descending\"]],[24,[\"headers\"]],[24,[\"model\"]]]],{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],\"row\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[11,\"data-title\",[29,[[28,\"t\",[\"formEnvVar.labels.key\"],null],\":\"]]],[10,\"class\",\"force-wrap\"],[8],[1,[23,3,[\"key\"]],false],[9],[0,\"\\n      \"],[7,\"td\",true],[11,\"data-title\",[29,[[28,\"t\",[\"formEnvVar.labels.value\"],null],\":\"]]],[10,\"class\",\"force-wrap\"],[8],[1,[23,3,[\"value\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],\"norows\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[11,\"colspan\",[29,[[23,1,[\"fullColspan\"]]]]],[10,\"class\",\"text-center text-muted pt-20 pb-20\"],[8],[1,[28,\"t\",[\"formEnvVar.noData\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1,2,3]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/form-env-var/template.hbs"
    }
  });

  _exports.default = _default;
});