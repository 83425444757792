define("ui/volumes/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EP6QSf+f",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cru-persistent-volume-claim\",null,[[\"model\",\"persistentVolumes\",\"storageClasses\",\"mode\",\"actuallySave\",\"done\",\"cancel\"],[[24,[\"model\",\"pvc\"]],[24,[\"model\",\"persistentVolumes\"]],[24,[\"model\",\"storageClasses\"]],\"new\",true,[28,\"action\",[[23,0,[]],\"done\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/volumes/new/template.hbs"
    }
  });

  _exports.default = _default;
});