define("ui/components/cluster/cis/scan/table-row/component", ["exports", "ui/components/cluster/cis/scan/table-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    error: Ember.computed('model.status.conditions.@each', function () {
      return this.model.status.conditions.find(function (condition) {
        return condition.type === 'Failed';
      });
    }),
    errorMessage: Ember.computed('error.message', function () {
      return this.error.message;
    })
  });

  _exports.default = _default;
});