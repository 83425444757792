define("ui/components/progress-bar-multi/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/IMcM5QE",
    "block": "{\"symbols\":[\"obj\"],\"statements\":[[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"aria-describedby\",\"tooltipFor\",\"inlineBlock\",\"classNames\"],[\"tooltip-basic\",[24,[\"tooltipContent\"]],[24,[\"tooltipTemplate\"]],\"tooltip-base\",\"progress-bar\",true,\"full-width\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"progress\"],[8],[4,\"each\",[[24,[\"pieces\"]]],null,{\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"progress-bar \",[23,1,[\"color\"]]]]],[11,\"style\",[23,1,[\"css\"]]],[8],[9]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/progress-bar-multi/template.hbs"
    }
  });

  _exports.default = _default;
});