define("ui/authenticated/cluster/security/members/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HzPyP2QQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header clearfix\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pull-left\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"membersPage.add.title\"],[[\"type\"],[\"Cluster\"]]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"section\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"box mt-30\"],[8],[0,\"\\n    \"],[1,[28,\"form-scoped-roles\",null,[[\"model\",\"type\",\"cancel\",\"doneSaving\"],[[24,[\"model\"]],\"cluster\",[28,\"action\",[[23,0,[]],\"doneSaving\"],null],[28,\"action\",[[23,0,[]],\"doneSaving\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/security/members/new/template.hbs"
    }
  });

  _exports.default = _default;
});