define("ui/catalog-tab/launch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "//69emDY",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"new-catalog\",null,[[\"cancel\",\"catalogApp\",\"catalogTemplate\",\"namespaceResource\",\"namespaces\",\"parentRoute\",\"selectedTemplateUrl\",\"templateKind\",\"templateResource\",\"upgrade\",\"versionLinks\",\"versionsArray\",\"istio\"],[[28,\"action\",[[23,0,[]],\"cancel\"],null],[24,[\"model\",\"catalogApp\"]],[24,[\"model\",\"catalogTemplate\"]],[24,[\"model\",\"namespace\"]],[24,[\"model\",\"namespaces\"]],[24,[\"parentRoute\"]],[24,[\"model\",\"catalogTemplateUrl\"]],[24,[\"model\",\"tplKind\"]],[24,[\"model\",\"tpl\"]],[24,[\"model\",\"upgradeTemplate\"]],[24,[\"model\",\"versionLinks\"]],[24,[\"model\",\"versionsArray\"]],[24,[\"istio\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/catalog-tab/launch/template.hbs"
    }
  });

  _exports.default = _default;
});