define("ui/templates/tooltip-basic-literal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Otlp1il5",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"model\",\"label\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/tooltip-basic-literal.hbs"
    }
  });

  _exports.default = _default;
});