define("ui/authenticated/project/hpa/detail/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vosEjWOm",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cru-hpa\",null,[[\"model\",\"deployments\",\"mode\"],[[24,[\"model\",\"hpa\"]],[24,[\"model\",\"deployments\"]],\"view\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/hpa/detail/index/template.hbs"
    }
  });

  _exports.default = _default;
});