define("ui/components/cru-persistent-volume/component", ["exports", "shared/mixins/view-new-edit", "ui/components/cru-persistent-volume/template", "ui/models/volume", "shared/utils/parse-unit", "ui/utils/constants"], function (_exports, _viewNewEdit, _template, _volume, _parseUnit, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_viewNewEdit.default, {
    intl: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    features: Ember.inject.service(),
    layout: _template.default,
    model: null,
    sourceName: null,
    titleKey: 'cruPersistentVolume.title',
    storageClasses: null,
    capacity: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'storageClasses', Ember.get(this, 'clusterStore').all('storageclass'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var primaryResource = this.primaryResource;
      var _primaryResource$sour = primaryResource.sourceName,
          sourceName = _primaryResource$sour === void 0 ? '' : _primaryResource$sour;

      if (Ember.get(this, 'isNew')) {
        Ember.set(this, 'capacity', 10);
      } else {
        var source = Ember.get(primaryResource, sourceName);

        if (sourceName === 'csi' && source.driver && source.driver === 'driver.longhorn.io') {
          Ember.set(this, 'sourceName', 'csi-volume-longhorn');
        } else {
          Ember.set(this, 'sourceName', sourceName);
        }

        var capacity = Ember.get(primaryResource, 'capacity.storage');

        if (capacity) {
          var bytes = (0, _parseUnit.parseSi)(capacity);
          var gib = bytes / Math.pow(1024, 3);
          Ember.set(this, 'capacity', gib);
        }
      }
    },
    actions: {
      updateOptions: function updateOptions(ary) {
        Ember.set(this, 'primaryResource.mountOptions', ary);
      },
      updateNodeAffinities: function updateNodeAffinities(nodeSelector) {
        if (nodeSelector.nodeSelectorTerms.length > 0) {
          Ember.set(this, 'primaryResource.nodeAffinity', {
            required: nodeSelector
          });
        } else {
          Ember.set(this, 'primaryResource.nodeAffinity', null);
        }
      }
    },
    sourceChoices: Ember.computed('intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var out = (0, _volume.getSources)('persistent').map(function (p) {
        var entry = Object.assign({}, p);
        var key = "volumeSource.".concat(entry.name, ".title");

        if (intl.exists(key)) {
          entry.label = intl.t(key);
          entry.priority = 1;
        } else {
          entry.label = entry.name;
          entry.priority = 2;
        }

        return entry;
      });
      return out.sortBy('priority', 'label');
    }),
    supportedSourceChoices: Ember.computed('sourceChoices', function () {
      var showUnsupported = Ember.get(this, 'features').isFeatureEnabled(_constants.default.FEATURES.UNSUPPORTED_STORAGE_DRIVERS);
      return Ember.get(this, 'sourceChoices').filter(function (choice) {
        return showUnsupported || choice.supported;
      });
    }),
    sourceDisplayName: Ember.computed('sourceName', 'sourceChoices.[]', function () {
      var sourceChoices = this.sourceChoices,
          sourceName = this.sourceName;
      var match = sourceChoices.findBy('name', sourceName);
      return match ? Ember.get(match, 'label') : '';
    }),
    sourceComponent: Ember.computed('sourceName', function () {
      var name = Ember.get(this, 'sourceName');
      var sources = (0, _volume.getSources)('persistent');
      var entry = sources.findBy('name', name);

      if (entry) {
        return "volume-source/source-".concat(name);
      }
    }),
    willSave: function willSave() {
      var vol = Ember.get(this, 'primaryResource');
      var entry = (0, _volume.getSources)('persistent').findBy('name', Ember.get(this, 'sourceName'));
      var intl = Ember.get(this, 'intl');
      var errors = [];

      if (!entry) {
        errors.push(intl.t('validation.required', {
          key: intl.t('cruPersistentVolume.source.label')
        }));
        Ember.set(this, 'errors', errors);
        return false;
      }

      if (vol.csi && vol.csi.driver === _constants.default.STORAGE.LONGHORN_PROVISIONER_KEY) {
        if (Ember.isEmpty(vol.csi.volumeHandle)) {
          errors.push(intl.t('validation.required', {
            key: intl.t('cruPersistentVolumeClaim.volumeHandleRequired.label')
          }));
          Ember.set(this, 'errors', errors);
          return false;
        }
      }

      vol.clearSourcesExcept(entry.value);
      var capacity = Ember.get(this, 'capacity');

      if (capacity) {
        Ember.set(vol, 'capacity', {
          storage: "".concat(capacity, "Gi")
        });
      } else {
        errors.push(intl.t('validation.required', {
          key: intl.t('cruPersistentVolumeClaim.capacity.label')
        }));
        Ember.set(this, 'errors', errors);
        return false;
      }

      return this._super.apply(this, arguments);
    },
    doneSaving: function doneSaving() {
      if (this.cancel) {
        this.cancel();
      }
    }
  });

  _exports.default = _default;
});