define("ui/components/modal-rollback-mc-app/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-rollback-mc-app/template", "jsondiffpatch", "moment"], function (_exports, _modalBase, _template, _jsondiffpatch, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HIDDEN_FIELDS = ['digest', 'created', 'createdTS', 'links', 'uuid', 'id', 'name'];

  function sanitizeToRemoveHiddenKeys(config) {
    HIDDEN_FIELDS.forEach(function (key) {
      if (config.hasOwnProperty(key)) {
        delete config[key];
      }
    });
    return config;
  }

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['large-modal'],
    loading: true,
    revisions: null,
    revisionId: null,
    multiClusterAppHasUpgradeStrategy: Ember.computed.notEmpty('model.upgradeStrategy.rollingUpdate'),
    didReceiveAttrs: function didReceiveAttrs() {
      this.initModelWithClone();
      this.getMultiClusterAppRevisions();
    },
    actions: {
      save: function save(cb) {
        var _this = this;

        var revisionId = this.revisionId;
        var neu = {
          revisionId: revisionId
        };

        if (Ember.get(this, 'multiClusterAppHasUpgradeStrategy')) {
          Ember.set(neu, 'batch', this.model.upgradeStrategy.rollingUpdate);
        }

        this.model.doAction('rollback', {
          revisionId: revisionId
        }).then(function () {
          return _this.send('cancel');
        }).finally(function () {
          return cb();
        });
      }
    },
    choices: Ember.computed('revisions.[]', function () {
      return (Ember.get(this, 'revisions') || []).sortBy('created').reverse().map(function (r) {
        var time = (0, _moment.default)(Ember.get(r, 'created'));
        return {
          label: "".concat(Ember.get(r, 'name'), ": ").concat(time.format('YYYY-MM-DD HH:mm:ss'), " (").concat(time.fromNow(), ")"),
          value: Ember.get(r, 'name'),
          data: r
        };
      });
    }),
    currentMultiClusterAppRevision: Ember.computed('choices.[]', 'revisionId', 'selectedMultiClusterAppRevision', function () {
      return Ember.get(this, 'choices.firstObject.data');
    }),
    selectedMultiClusterAppRevision: Ember.computed('choices.[]', 'revisionId', 'currentMultiClusterAppRevision', function () {
      var match = Ember.get(this, 'choices').findBy('value', Ember.get(this, 'revisionId'));
      return match ? match.data : null;
    }),
    answersDiff: Ember.computed('currentMultiClusterAppRevision', 'selectedMultiClusterAppRevision', 'revisionId', function () {
      if (Ember.get(this, 'currentMultiClusterAppRevision') && Ember.get(this, 'selectedMultiClusterAppRevision')) {
        var currentMultiClusterAppRevision = this.currentMultiClusterAppRevision,
            selectedMultiClusterAppRevision = this.selectedMultiClusterAppRevision;
        return this.generateAnswersJsonDiff(currentMultiClusterAppRevision, selectedMultiClusterAppRevision);
      }
    }),
    initModelWithClone: function initModelWithClone() {
      Ember.set(this, 'model', Ember.get(this, 'modalService.modalOpts.originalModel').clone());
    },
    generateAnswersJsonDiff: function generateAnswersJsonDiff(left, right) {
      var delta = _jsondiffpatch.default.diff(sanitizeToRemoveHiddenKeys(left), sanitizeToRemoveHiddenKeys(right));

      _jsondiffpatch.default.formatters.html.hideUnchanged();

      return _jsondiffpatch.default.formatters.html.format(delta, left).htmlSafe();
    },
    getMultiClusterAppRevisions: function getMultiClusterAppRevisions() {
      var _this2 = this;

      var revisionsURL = Ember.get(this, 'modalService.modalOpts.revisionsLink');
      return Ember.get(this, 'store').rawRequest({
        url: revisionsURL,
        method: 'GET'
      }).then(function (revs) {
        return Ember.set(_this2, 'revisions', revs.body.data);
      }).catch(function (err) {
        _this2.send('cancel');

        Ember.get(_this2, 'growl').fromError(err);
      }).finally(function () {
        return Ember.set(_this2, 'loading', false);
      });
    }
  });

  _exports.default = _default;
});