define("ui/update-critical-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BEEJFNL9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"login\"],[8],[0,\"\\n  \"],[1,[28,\"settings/server-url\",null,[[\"serverUrlSetting\",\"initServerUrl\"],[[24,[\"model\",\"serverUrlSetting\"]],[24,[\"model\",\"serverUrl\"]]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/update-critical-settings/template.hbs"
    }
  });

  _exports.default = _default;
});