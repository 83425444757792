define("ui/components/project-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "crf8JNzG",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"p\",\"dt\"],\"statements\":[[4,\"sortable-table\",null,[[\"tableClassNames\",\"paging\",\"pagingLabel\",\"headers\",\"descending\",\"body\",\"sortBy\"],[\"bordered mt-30\",true,\"pagination.project\",[24,[\"headers\"]],[24,[\"descending\"]],[24,[\"model\"]],[24,[\"sortBy\"]]]],{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],\"row\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"project-row\",null,[[\"model\",\"dt\"],[[23,3,[]],[23,4,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],\"nomatch\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[7,\"td\",true],[11,\"colspan\",[29,[[23,1,[\"fullColspan\"]]]]],[10,\"class\",\"text-center text-muted lacsso pt-20 pb-20\"],[8],[1,[28,\"t\",[\"projectsPage.noMatch\"],null],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],\"norows\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[7,\"td\",true],[11,\"colspan\",[29,[[23,1,[\"fullColspan\"]]]]],[10,\"class\",\"text-center text-muted lacsso pt-20 pb-20\"],[8],[1,[28,\"t\",[\"projectsPage.noData\"],null],false],[9],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1,2,3,4]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/project-table/template.hbs"
    }
  });

  _exports.default = _default;
});