define("ui/components/identity-avatar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4HaAOk8B",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[4,\"if\",[[24,[\"avatarSrc\"]]],null,{\"statements\":[[4,\"if\",[[28,\"and\",[[24,[\"url\"]],[24,[\"link\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[29,[[22,\"url\"]]]],[10,\"target\",\"_blank\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[29,[[22,\"avatarSrc\"]]]],[11,\"width\",[29,[[22,\"size\"]]]],[11,\"height\",[29,[[22,\"size\"]]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"img\",true],[11,\"src\",[29,[[22,\"avatarSrc\"]]]],[11,\"width\",[29,[[22,\"size\"]]]],[11,\"height\",[29,[[22,\"size\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"gh-placeholder\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/identity-avatar/template.hbs"
    }
  });

  _exports.default = _default;
});