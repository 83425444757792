define("ui/components/certificate-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Y6LvHBL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"row-check\"],[10,\"valign\",\"middle\"],[10,\"style\",\"padding-top: 2px;\"],[8],[0,\"\\n  \"],[1,[28,\"check-box\",null,[[\"nodeId\"],[[24,[\"model\",\"id\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"state\"]]]]],[10,\"class\",\"state\"],[8],[0,\"\\n  \"],[1,[28,\"badge-state\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"name\"]]]]],[10,\"class\",\"force-wrap\"],[8],[0,\"\\n  \"],[4,\"link-to-external\",[\"authenticated.project.certificates.detail\",[24,[\"model\",\"id\"]]],null,{\"statements\":[[1,[24,[\"model\",\"displayName\"]],false]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"cn\"]]]]],[8],[0,\"\\n  \"],[1,[24,[\"model\",\"displayDomainName\"]],true],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"expires\"]]]]],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"model\",\"expiresSoon\"]],\"text-bold text-danger\"],null]]]],[8],[0,\"\\n    \"],[1,[28,\"date-from-now\",[[24,[\"model\",\"expiresDate\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"actions\"]]]]],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[1,[28,\"action-menu\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/certificate-row/template.hbs"
    }
  });

  _exports.default = _default;
});