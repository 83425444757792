define("ui/authenticated/project/registries/detail/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "boVPBRy0",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cru-registry\",null,[[\"originalModel\",\"model\",\"mode\",\"done\",\"cancel\"],[[24,[\"originalModel\"]],[24,[\"model\"]],\"edit\",[28,\"action\",[[23,0,[]],\"back\"],null],[28,\"action\",[[23,0,[]],\"back\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/registries/detail/edit/template.hbs"
    }
  });

  _exports.default = _default;
});