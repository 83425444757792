define("ui/components/modal-new-vct/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B+rRoUOh",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cru-volume-claim-template\",null,[[\"model\",\"namespace\",\"selectNamespace\",\"mode\",\"actuallySave\",\"doSave\",\"cancel\",\"workload\"],[[24,[\"model\"]],[24,[\"namespace\"]],false,[28,\"if\",[[24,[\"model\",\"id\"]],\"edit\",\"new\"],null],false,[28,\"action\",[[23,0,[]],\"doSave\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null],[24,[\"workload\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-new-vct/template.hbs"
    }
  });

  _exports.default = _default;
});