define("ui/authenticated/project/dns/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jHisSX/a",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cru-dns\",null,[[\"model\",\"dnsRecords\",\"workloads\",\"mode\",\"done\",\"cancel\"],[[24,[\"model\",\"record\"]],[24,[\"model\",\"dnsRecords\"]],[24,[\"model\",\"workloads\"]],\"new\",[28,\"action\",[[23,0,[]],\"done\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/dns/new/template.hbs"
    }
  });

  _exports.default = _default;
});