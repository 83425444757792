define("ui/authenticated/project/console/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vevmx9zY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"shell-popup\"],[8],[0,\"\\n  \"],[1,[28,\"container-shell\",null,[[\"instance\",\"command\",\"windows\",\"containerName\",\"showProtip\",\"cols\",\"rows\",\"dismiss\",\"disconncted\"],[[24,[\"model\"]],[24,[\"command\"]],[24,[\"windows\"]],[24,[\"containerName\"]],false,80,24,[28,\"action\",[[23,0,[]],\"cancel\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/console/template.hbs"
    }
  });

  _exports.default = _default;
});