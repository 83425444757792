define("ui/components/volume-source/source-host-path/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zcsBdY1L",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[\"volumeSource.host-path.path.label\"],null],false],[9],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[24,[\"editing\"]],[24,[\"config\",\"path\"]]]],{\"statements\":[[0,\"      \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[24,[\"config\",\"path\"]],\"form-control\",[28,\"t\",[\"cruPersistentVolume.local.path.placeholder\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[\"volumeSource.host-path.kind.label\"],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"editing\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"new-select\",null,[[\"value\",\"content\",\"localizedLabel\"],[[24,[\"config\",\"kind\"]],[24,[\"kindChoices\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"config\",\"kind\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[[28,\"concat\",[\"volumeSource.host-path.kind.\",[24,[\"config\",\"kind\"]]],null]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"volumeSource.host-path.kind.Any\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/volume-source/source-host-path/template.hbs"
    }
  });

  _exports.default = _default;
});