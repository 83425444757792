define("ui/components/namespace-quota-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MGW5CVYQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"pr-10\"],[8],[0,\"\\n  \"],[1,[28,\"resource-quota-select\",null,[[\"editing\",\"quota\"],[false,[24,[\"quota\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"pr-10\"],[8],[0,\"\\n  \"],[1,[28,\"progress-bar-multi\",null,[[\"values\",\"tooltipValues\",\"tooltipArrayOrString\",\"tooltipTemplate\",\"max\",\"minPercent\"],[[24,[\"quota\",\"currentProjectUse\"]],[24,[\"quota\",\"totalLimits\"]],\"array\",\"-ns-quota-progressbar\",[24,[\"quota\",\"max\"]],0]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[8],[0,\"\\n  \"],[1,[28,\"input-resource-quota\",null,[[\"editing\",\"quota\",\"key\"],[[24,[\"editing\"]],[24,[\"quota\"]],\"value\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/namespace-quota-row/template.hbs"
    }
  });

  _exports.default = _default;
});