define("ui/components/new-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WV51xt7A",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"pt-15 pb-30\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[1,[28,\"t\",[\"newPassword.password\"],null],false],[1,[22,\"field-required\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inline-form\"],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"placeholder\",\"value\"],[\"password\",[28,\"t\",[\"newPassword.placeholder\"],null],[24,[\"newPassword\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pt-15 pb-30\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[1,[28,\"t\",[\"newPassword.confirmPassword\"],null],false],[1,[22,\"field-required\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"inline-form\"],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"placeholder\",\"value\"],[\"password\",[28,\"t\",[\"newPassword.placeholder\"],null],[24,[\"confirmPassword\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"passwordsMatch\"]],\"hide\",\"\"],null]]]],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"newPassword.passwordNoMatch\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/new-password/template.hbs"
    }
  });

  _exports.default = _default;
});