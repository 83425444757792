define("ui/models/nodetemplate", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "shared/utils/util", "shared/mixins/node-driver"], function (_exports, _resource, _denormalize, _util, _nodeDriver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _resource.default.extend({
    intl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    globalStore: Ember.inject.service(),
    type: 'nodeTemplate',
    canClone: true,
    creator: (0, _denormalize.reference)('creatorId', 'user', 'globalStore'),
    init: function init() {
      this._super.apply(this, arguments);

      var driver = this.driver;

      if (driver) {
        this.initDisplayLocation(driver);
        this.initDisplaySize(driver);
      }
    },
    config: Ember.computed('driver', function () {
      var driver = Ember.get(this, 'driver');
      return Ember.get(this, "".concat(driver, "Config"));
    }),
    displayProvider: Ember.computed('driver', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var driver = Ember.get(this, 'driver');
      var key = "nodeDriver.displayName.".concat(driver);

      if (intl.exists(key)) {
        return intl.t(key);
      } else {
        return (0, _util.ucFirst)(driver);
      }
    }),
    cloneForNew: function cloneForNew() {
      var copy = this._super();

      delete copy.annotations;
      return copy;
    },
    actions: {
      edit: function edit() {
        var driver = Ember.get(this, 'driver');
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template', {
          driver: driver,
          config: Ember.get(this, "".concat(driver, "Config")),
          nodeTemplate: this,
          edit: true
        });
      },
      clone: function clone() {
        var driver = this.driver;
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template', {
          driver: driver,
          config: Ember.get(this, "".concat(driver, "Config")),
          nodeTemplate: this,
          clone: true
        });
      }
    },
    _displayVar: function _displayVar(keyOrFn) {
      var intl = Ember.get(this, 'intl');

      if (keyOrFn) {
        if (typeof keyOrFn === 'function') {
          return keyOrFn.call(this);
        } else {
          return Ember.get(this, keyOrFn) || intl.t('generic.unknown');
        }
      } else {
        return intl.t('generic.unknown');
      }
    },
    clearConfigsExcept: function clearConfigsExcept(keep) {
      var keys = this.allKeys().filter(function (x) {
        return x.endsWith('Config');
      });

      for (var key, i = 0; i < keys.length; i++) {
        key = keys[i];

        if (key !== keep && Ember.get(this, key)) {
          Ember.set(this, key, null);
        }
      }
    },
    initDisplayLocation: function initDisplayLocation(driver) {
      var location = (0, _nodeDriver.getDisplayLocation)(driver);
      var computedKeys = null;

      if (location && location.keyOrKeysToWatch) {
        computedKeys = Ember.isArray(location.keyOrKeysToWatch) ? location.keyOrKeysToWatch : [location.keyOrKeysToWatch];
        this.registerDynamicComputedProperty('displayLocation', computedKeys, location.getDisplayProperty);
      } else {
        Ember.set(this, 'displayLocation', Ember.get(this, 'config.region') || 'N/A');
      }
    },
    initDisplaySize: function initDisplaySize(driver) {
      var size = (0, _nodeDriver.getDisplaySize)(driver);
      var computedKeys = null;

      if (size && size.keyOrKeysToWatch) {
        computedKeys = Ember.isArray(size.keyOrKeysToWatch) ? size.keyOrKeysToWatch : [size.keyOrKeysToWatch];
        this.registerDynamicComputedProperty('displaySize', computedKeys, size.getDisplayProperty);
      } else {
        Ember.set(this, 'displaySize', Ember.get(this, 'config.size') || 'N/A');
      }
    },
    registerDynamicComputedProperty: function registerDynamicComputedProperty(propertyName, watchedKeys, key) {
      var _this = this;

      Ember.defineProperty(this, propertyName, Ember.computed.apply(void 0, _toConsumableArray(watchedKeys).concat([function () {
        return _this._displayVar(key);
      }])));
    }
  });

  _exports.default = _default;
});