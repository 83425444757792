define("ui/components/resource-quota-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "euctDgYq",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"searchable-select\",null,[[\"content\",\"value\",\"localizedLabel\",\"readOnly\"],[[24,[\"resourceChoices\"]],[24,[\"quota\",\"key\"]],true,[28,\"not\",[[24,[\"editing\"]]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/resource-quota-select/template.hbs"
    }
  });

  _exports.default = _default;
});