define("ui/components/form-node-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fYBSDSOO",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"box mb-10 pt-5\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"editing\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[8],[0,\"\\n          \"],[7,\"button\",false],[12,\"class\",\"btn bg-transparent text-small vertical-middle\"],[3,\"action\",[[23,0,[]],\"removeRule\",[24,[\"rule\"]]]],[8],[0,\"\\n            \"],[1,[28,\"t\",[\"formNodeAffinity.removeRuleLabel\"],null],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[2,\" 28px is the height of the button, no jumpy \"],[0,\"\\n        \"],[7,\"div\",true],[10,\"style\",\"height: 28px;\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n    \"],[1,[28,\"form-node-requirement\",null,[[\"term\",\"editing\"],[[24,[\"rule\"]],[24,[\"editing\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/form-node-selector/template.hbs"
    }
  });

  _exports.default = _default;
});