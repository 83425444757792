define("ui/components/pod-dots/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SCLQf10j",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[24,[\"pagedContent\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"container-dot\",null,[[\"model\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-center text-muted pt-20 pb-20\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"searchText\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"containersPage.table.noMatch\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"containersPage.table.noData\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[28,\"gt\",[[24,[\"filtered\",\"length\"]],[24,[\"pagedContent\",\"length\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"page-numbers\",null,[[\"content\",\"class\",\"indexTo\",\"indexFrom\",\"totalCount\",\"textLabel\"],[[24,[\"pagedContent\"]],\"text-center mt-10 mb-10 no-select\",[24,[\"indexTo\"]],[24,[\"indexFrom\"]],[24,[\"filtered\",\"length\"]],\"pagination.container\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/pod-dots/template.hbs"
    }
  });

  _exports.default = _default;
});