define("ui/authenticated/project/project-catalogs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lYFhZdL3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header clearfix\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"right-buttons\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn bg-primary btn-sm\"],[3,\"action\",[[23,0,[]],\"add\"]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"catalogSettings.more.addActionLabel\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"catalogSettings.header\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"istio\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-12\"],[8],[0,\"\\n      \"],[1,[28,\"banner-message\",null,[[\"icon\",\"color\",\"message\"],[\"icon-info\",\"bg-info mb-0 mt-10\",[28,\"t\",[\"globalDnsPage.entriesPage.istioHelp\"],[[\"htmlSafe\"],[true]]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"custom-catalog\",null,[[\"catalogs\",\"mode\"],[[24,[\"filtered\"]],\"project\"]]],false],[0,\"\\n\\n\\n\"],[7,\"div\",true],[10,\"class\",\"footer-actions mt-20\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-transparent\"],[3,\"action\",[[23,0,[]],\"goBack\"]],[8],[1,[28,\"t\",[\"generic.back\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/project-catalogs/template.hbs"
    }
  });

  _exports.default = _default;
});