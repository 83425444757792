define("ui/templates/tooltip-multi-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ILR6yLRC",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-left\"],[8],[0,\"\\n    \"],[1,[23,1,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/tooltip-multi-lines.hbs"
    }
  });

  _exports.default = _default;
});