define("ui/components/notifier/notifier-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PE5f2iUP",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"row\",\"dt\"],\"statements\":[[4,\"sortable-table\",null,[[\"tableClassNames\",\"classNames\",\"bulkActions\",\"paging\",\"search\",\"sortBy\",\"descending\",\"headers\",\"body\"],[\"bordered\",\"grid sortable-table\",true,true,true,[24,[\"sortBy\"]],[24,[\"descending\"]],[24,[\"headers\"]],[24,[\"filteredNotifiers\"]]]],{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],\"row\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"notifier/notifier-row\",null,[[\"model\"],[[23,3,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],\"nomatch\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[11,\"colspan\",[29,[[23,1,[\"fullColspan\"]]]]],[10,\"class\",\"text-center text-muted pt-20 pb-20\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"notifierPage.index.table.noMatch\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,2,[]],\"norows\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n      \"],[7,\"td\",true],[11,\"colspan\",[29,[[23,1,[\"fullColspan\"]]]]],[10,\"class\",\"text-center text-muted pt-20 pb-20\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"notifierPage.index.table.noData\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1,2,3,4]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/notifier/notifier-table/template.hbs"
    }
  });

  _exports.default = _default;
});