define("ui/templates/tooltip-basic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BcR2msEQ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"t\",[[24,[\"model\",\"label\"]]],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/tooltip-basic.hbs"
    }
  });

  _exports.default = _default;
});