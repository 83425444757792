define("ui/components/modal-telemetry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xJz2HyNP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"welcome-copy\"],[8],[0,\"\\n\"],[0,\"\\n\"],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"alert-header\"],[8],[1,[28,\"t\",[\"modalWelcome.header\"],[[\"appName\"],[[24,[\"settings\",\"appName\"]]]]],false],[9],[0,\"\\n\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-4\"],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"app\",\"baseAssets\"]],\"assets/images/welcome-scene.svg\"]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-8\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"telemetryOpt.subtext\"],[[\"appName\",\"htmlSafe\"],[[24,[\"settings\",\"appName\"]],true]]],false],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col-inline checkbox pt-30 text-small\"],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"optIn\"]]]]],false],[0,\" \"],[1,[28,\"t\",[\"telemetryOpt.label\"],null],false],[9],[0,\" \"],[7,\"a\",true],[11,\"href\",[29,[[24,[\"settings\",\"docsBase\"]],\"/faq/telemetry\"]]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener nofollow\"],[8],[1,[28,\"t\",[\"telemetryOpt.learnMore.label\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"footer-actions\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn bg-primary\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[28,\"t\",[\"modalWelcome.closeModal\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-telemetry/template.hbs"
    }
  });

  _exports.default = _default;
});