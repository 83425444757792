define("ui/components/form-scoped-roles/component", ["exports", "ui/utils/errors", "ui/components/form-scoped-roles/template", "ui/mixins/new-or-edit", "jquery"], function (_exports, _errors, _template, _newOrEdit, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var CUSTOM = 'custom';

  var _default = Ember.Component.extend(_newOrEdit.default, {
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    user: null,
    primaryResource: null,
    editing: false,
    type: null,
    cTyped: null,
    stdUser: null,
    admin: null,
    principal: null,
    init: function init() {
      this._super.apply(this, arguments);

      var model = {
        type: "".concat(Ember.get(this, 'type'), "RoleTemplateBinding")
      };
      Ember.set(model, "".concat(Ember.get(this, 'type'), "Id"), Ember.get(this, "model.".concat(Ember.get(this, 'type'), ".id")));
      Ember.setProperties(this, {
        primaryResource: this.make(model),
        stdUser: "".concat(Ember.get(this, 'type'), "-member"),
        admin: "".concat(Ember.get(this, 'type'), "-owner"),
        cTyped: Ember.get(this, 'type').capitalize()
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        var elem = (0, _jquery.default)('INPUT')[0];

        if (elem) {
          setTimeout(function () {
            elem.focus();
          }, 250);
        }
      });
    },
    actions: {
      gotError: function gotError(err) {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);
      },
      addAuthorized: function addAuthorized(principal) {
        Ember.set(this, 'principal', principal);
      },
      cancel: function cancel() {
        if (this.cancel) {
          this.cancel();
        }
      },
      save: function save(cb) {
        var _this2 = this;

        Ember.set(this, 'errors', null);
        var mode = Ember.get(this, 'mode');
        var add = [];
        var pr = Ember.get(this, 'primaryResource');
        var userRoles = Ember.get(this, 'userRoles');
        var principal = Ember.get(this, 'principal');

        if (principal) {
          if (Ember.get(principal, 'principalType') === 'user') {
            Ember.set(pr, 'userPrincipalId', Ember.get(principal, 'id'));
          } else if (Ember.get(principal, 'principalType') === 'group') {
            Ember.set(pr, 'groupPrincipalId', Ember.get(principal, 'id'));
          }
        }

        switch (mode) {
          case "".concat(Ember.get(this, 'type'), "-owner"):
          case "".concat(Ember.get(this, 'type'), "-member"):
          case 'read-only':
            Ember.set(pr, 'roleTemplateId', mode);
            add = [pr];
            break;

          case CUSTOM:
            add = Ember.get(this, 'customToAdd').map(function (x) {
              var neu = Ember.get(_this2, 'primaryResource').clone();
              Ember.set(neu, 'roleTemplateId', Ember.get(x, 'role.id'));
              return neu;
            });
            break;

          default:
            var addMatch = userRoles.find(function (ur) {
              return Ember.get(ur, 'active');
            });

            if (addMatch) {
              Ember.set(pr, 'roleTemplateId', Ember.get(addMatch, 'role.id'));
              add = [pr];
            }

            break;
        }

        if (!this.validate()) {
          if (cb) {
            cb();
          }

          return;
        }

        return Ember.RSVP.all(add.map(function (x) {
          return x.save();
        })).then(function () {
          return _this2.doneSaving();
        }).catch(function (err) {
          Ember.set(_this2, 'errors', [_errors.default.stringify(err)]);
          return cb(false);
        });
      }
    },
    showAdmin: Ember.computed('model.roles.@each.id', 'mode', function () {
      var id = "".concat(Ember.get(this, 'type'), "-owner");
      var role = Ember.get(this, 'model.roles').findBy('id', id);

      if (Ember.get(this, 'mode') === id) {
        return true;
      }

      if (role && Ember.get(role, 'locked') !== true) {
        return true;
      }

      return false;
    }),
    showStdUser: Ember.computed('model.roles.@each.id', 'mode', function () {
      var id = "".concat(Ember.get(this, 'type'), "-member");
      var role = Ember.get(this, 'model.roles').findBy('id', id);

      if (Ember.get(this, 'mode') === id) {
        return true;
      }

      if (role && Ember.get(role, 'locked') !== true) {
        return true;
      }

      return false;
    }),
    showReadOnly: Ember.computed('model.roles.@each.id', 'mode', function () {
      var id = 'read-only';
      var role = Ember.get(this, 'model.roles').findBy('id', id);

      if (Ember.get(this, 'mode') === id) {
        return true;
      }

      if (role && Ember.get(role, 'locked') !== true) {
        return true;
      }

      return false;
    }),
    baseRoles: Ember.computed(function () {
      return ["".concat(Ember.get(this, 'type'), "-admin"), "".concat(Ember.get(this, 'type'), "-owner"), "".concat(Ember.get(this, 'type'), "-member"), 'read-only'];
    }),
    userRoles: Ember.computed('model.roles.[]', function () {
      var _this3 = this;

      var roles = Ember.get(this, 'model.roles');
      var userDef = roles.filter(function (role) {
        return !Ember.get(role, 'builtin') && !Ember.get(role, 'external') && !Ember.get(role, 'hidden') && (Ember.get(role, 'context') === Ember.get(_this3, 'type') || !Ember.get(role, 'context'));
      });
      return userDef.map(function (role) {
        return {
          role: role,
          active: false
        };
      });
    }),
    custom: Ember.computed('model.roles.[]', function () {
      // built in
      var roles = Ember.get(this, 'model.roles').filterBy('hidden', false);
      var excludes = Ember.get(this, 'baseRoles');
      var context = "".concat(Ember.get(this, 'type'));
      return roles.filter(function (role) {
        return !excludes.includes(role.id) && Ember.get(role, 'builtin') && Ember.get(role, 'context') === context;
      }).map(function (role) {
        return {
          role: role,
          active: false
        };
      });
    }),
    mode: Ember.computed('editing', {
      get: function get() {
        var mode = null;
        var memberId = "".concat(Ember.get(this, 'type'), "-member");
        var memberRole = Ember.get(this, 'model.roles').findBy('id', memberId);
        var ownerId = "".concat(Ember.get(this, 'type'), "-owner");
        var onwerRole = Ember.get(this, 'model.roles').findBy('id', ownerId);

        if (memberRole) {
          mode = memberId;
        } else if (Ember.get(this, 'userRoles.length')) {
          var userRole = Ember.get(this, 'userRoles.firstObject');
          Ember.set(userRole, 'active', true);
          mode = userRole;
        } else if (onwerRole) {
          mode = ownerId;
        } else {
          mode = CUSTOM;
        }

        return mode;
      },
      set: function set(key, value) {
        if (_typeof(value) === 'object') {
          var ur = Ember.get(this, 'userRoles').findBy('active', true);

          if (ur) {
            Ember.set(ur, 'active', false);
          }

          Ember.set(value, 'active', true); // value = get(value, 'role.id');
          // return get(value, 'role.id');
        } else {
          var _ur = Ember.get(this, 'userRoles').findBy('active', true);

          if (_ur) {
            Ember.run.next(function () {
              Ember.set(_ur, 'active', false);
            });
          }
        }

        return value;
      }
    }),
    customToAdd: Ember.computed('custom.@each.active', function () {
      return Ember.get(this, 'custom').filter(function (role) {
        return Ember.get(role, 'active');
      });
    }),
    make: function make(role) {
      return Ember.get(this, 'globalStore').createRecord(role);
    },
    validate: function validate() {
      var _this4 = this;

      var errors = this.get('errors', errors) || [];
      var principal = Ember.get(this, 'principal');

      if (!principal) {
        errors.push(this.get('intl').t('rolesPage.new.errors.memberReq'));
        Ember.set(this, 'errors', errors);
        return false;
      }

      var current = (Ember.get(this, 'model.roleBindings') || []).filter(function (role) {
        var id;

        if (Ember.get(_this4, 'type') === 'project') {
          id = Ember.get(_this4, 'scope.currentProject.id');
        } else {
          id = Ember.get(_this4, 'scope.currentCluster.id');
        }

        return id === Ember.get(role, "".concat(Ember.get(_this4, 'type'), "Id")) && Ember.get(role, 'userPrincipalId') === Ember.get(principal, 'id');
      });

      if (Ember.get(this, 'mode') === 'custom') {
        if (Ember.get(this, 'customToAdd.length') < 1) {
          errors.push(this.get('intl').t('rolesPage.new.errors.noSelectedRoles'));
        }

        (Ember.get(this, 'customToAdd') || []).forEach(function (add) {
          if (current.findBy('roleTemplateId', Ember.get(add, 'role.id'))) {
            errors.push(_this4.get('intl').t('rolesPage.new.errors.roleAlreadyExists', {
              key: Ember.get(add, 'role.displayName')
            }));
          }
        });
      } else if (current.findBy('roleTemplateId', Ember.get(this, 'primaryResource.roleTemplateId'))) {
        errors.push(this.get('intl').t('rolesPage.new.errors.roleAlreadyExists', {
          key: Ember.get(this, 'primaryResource.roleTemplate.displayName')
        }));
      }

      Ember.set(this, 'errors', errors);
      return this.get('errors.length') === 0;
    }
  });

  _exports.default = _default;
});