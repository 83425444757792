define("ui/templates/tooltip-action-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+R1tWlUy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"tooltip-content-inner tooltip-dot\"],[8],[0,\"\\n  \"],[1,[28,\"action-menu\",null,[[\"model\",\"showPrimary\",\"inTooltip\",\"class\"],[[24,[\"model\"]],false,true,\"pull-right tooltip-more-actions\"]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"display-name\"],[8],[1,[22,\"displayName\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"display-ip\"],[8],[1,[28,\"format-ip\",[[24,[\"model\",\"displayIp\"]]],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/tooltip-action-menu.hbs"
    }
  });

  _exports.default = _default;
});