define("ui/components/modal-kubectl/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SRveX4+W",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"container-shell\",null,[[\"url\",\"showProtip\",\"displayName\",\"dismiss\",\"disconncted\"],[[24,[\"url\"]],false,[24,[\"scope\",\"currentCluster\",\"displayName\"]],[28,\"action\",[[23,0,[]],\"cancel\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"footer-actions\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-primary\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[28,\"t\",[\"generic.closeModal\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-kubectl/template.hbs"
    }
  });

  _exports.default = _default;
});