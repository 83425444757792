define("ui/components/volume-source/source-flocker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uENiR3S/",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row mb-20\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[\"cruPersistentVolume.flocker.datasetName.label\"],null],false],[9],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[24,[\"editing\"]],[24,[\"config\",\"datasetName\"]]]],{\"statements\":[[0,\"        \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[24,[\"config\",\"datasetName\"]],\"form-control\",[28,\"t\",[\"cruPersistentVolume.flocker.datasetName.placeholder\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[\"cruPersistentVolume.flocker.datasetUUID.label\"],null],false],[9],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[24,[\"editing\"]],[24,[\"config\",\"datasetUUID\"]]]],{\"statements\":[[0,\"        \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[24,[\"config\",\"datasetUUID\"]],\"form-control\",[28,\"t\",[\"cruPersistentVolume.flocker.datasetUUID.placeholder\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/volume-source/source-flocker/template.hbs"
    }
  });

  _exports.default = _default;
});