define("ui/components/modal-edit-certificate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NSBaFigT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"horizontal-form container-fluid\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[1,[28,\"t\",[\"editCertificate.title\"],null],false],[9],[0,\"\\n\\n  \"],[1,[28,\"form-name-description\",null,[[\"model\",\"nameRequired\",\"descriptionShown\",\"namePlaceholder\",\"descriptionPlaceholder\"],[[24,[\"primaryResource\"]],true,false,\"editCertificate.name.placeholder\",\"editCertificate.description.placeholder\"]]],false],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"banner bg-info\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"banner-icon\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon icon-info\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"banner-message pt-10 pb-10\"],[8],[1,[28,\"t\",[\"editCertificate.noteKeyWriteOnly\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[28,\"cru-certificate\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"top-errors\",null,[[\"errors\"],[[24,[\"errors\"]]]]],false],[0,\"\\n\"],[1,[28,\"save-cancel\",null,[[\"editing\",\"save\",\"cancel\"],[true,[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-edit-certificate/template.hbs"
    }
  });

  _exports.default = _default;
});