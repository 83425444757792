define("ui/components/input-random-port/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pbkPBFl4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showEdit\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"input-integer\",null,[[\"class\",\"min\",\"max\",\"value\",\"placeholder\"],[\"form-control input-sm\",[24,[\"min\"]],[24,[\"max\"]],[24,[\"value\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"edit btn bg-transparent pl-0\"],[3,\"action\",[[23,0,[]],\"showEdit\"]],[8],[1,[28,\"t\",[[24,[\"standardKey\"]]],null],false],[0,\" \"],[7,\"i\",true],[10,\"class\",\"icon icon-edit\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/input-random-port/template.hbs"
    }
  });

  _exports.default = _default;
});