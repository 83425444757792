define("ui/components/container/form-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D+BJFwIp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"formImage.label\"],null],false],[1,[22,\"field-required\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n  \"],[1,[28,\"input-suggest\",null,[[\"value\",\"grouped\",\"placeholder\"],[[24,[\"userInput\"]],[24,[\"suggestions\"]],[28,\"t\",[\"formImage.container.placeholder\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/container/form-image/template.hbs"
    }
  });

  _exports.default = _default;
});