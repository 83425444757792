define("ui/components/container/form-scheduling/component", ["exports", "ui/components/container/form-scheduling/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    scheduling: null,
    // Request a specific host
    requestedHostId: null,
    // Is requesting a specific host allowed
    canRequestHost: true,
    // Initial host to start with
    initialHostId: null,
    // Internal properties
    isRequestedHost: false,
    editing: true,
    advanced: false,
    classNames: ['accordion-wrapper'],
    _allNodes: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, '_allNodes', Ember.get(this, 'globalStore').all('node'));
      Ember.set(this, 'advanced', !Ember.get(this, 'editing'));

      if (Ember.get(this, 'initialHostId')) {
        Ember.set(this, 'isRequestedHost', true);
        Ember.set(this, 'requestedHostId', Ember.get(this, 'initialHostId'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!Ember.get(this, 'expandFn')) {
        Ember.set(this, 'expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },
    isRequestedHostDidChange: Ember.observer('isRequestedHost', function () {
      var scheduling = Ember.get(this, 'scheduling');

      if (Ember.get(this, 'isRequestedHost')) {
        var hostId = Ember.get(this, 'requestedHostId') || Ember.get(this, 'hostChoices.firstObject.id');
        Object.keys(scheduling).forEach(function (key) {
          if (scheduling.node) {
            delete scheduling.node[key];
          }
        });
        Ember.set(this, 'requestedHostId', hostId);
      } else {
        Ember.set(this, 'requestedHostId', null);
        delete scheduling.node['nodeId'];
      }
    }),
    requestedHostIdDidChange: Ember.observer('requestedHostId', function () {
      var hostId = Ember.get(this, 'requestedHostId');

      if (Ember.get(this, 'scheduling.node')) {
        Ember.set(this, 'scheduling.node.nodeId', hostId);
      } else {
        Ember.set(this, 'scheduling.node', {
          nodeId: hostId
        });
      }
    }),
    selectedChoice: Ember.computed('_allNodes.@each.{id,clusterId,name,state}', function () {
      return Ember.get(this, 'hostChoices').findBy('id', Ember.get(this, 'initialHostId'));
    }),
    hostChoices: Ember.computed('_allNodes.@each.{id,clusterId,name,state}', function () {
      var list = Ember.get(this, '_allNodes').filter(function (node) {
        return !Ember.get(node, 'isUnschedulable');
      }).filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id')).map(function (host) {
        var hostLabel = Ember.get(host, 'displayName');

        if (Ember.get(host, 'state') !== 'active') {
          hostLabel += " (".concat(Ember.get(host, 'state'), ")");
        }

        return {
          id: Ember.get(host, 'id'),
          name: hostLabel
        };
      });
      return list.sortBy('name', 'id');
    })
  });

  _exports.default = _default;
});