define("ui/components/modal-feedback/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zyGvbMgw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"pt-10 pb-40 pl-40 pr-40 welcome-copy\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"header\"],[8],[1,[28,\"t\",[\"modalFeedback.header\"],[[\"appName\"],[[24,[\"settings\",\"appName\"]]]]],false],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"modalFeedback.subtitle\"],null],false],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"id\",\"feedback-form\"],[10,\"class\",\"clearfix\"],[10,\"style\",\"min-height: 100px;\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center p-20\"],[8],[7,\"i\",true],[10,\"class\",\"icon icon-spinner icon-spin\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"generic.loading\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"footer-actions pt-20\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn bg-primary\"],[12,\"disabled\",[22,\"loading\"]],[3,\"action\",[[23,0,[]],\"submit\"]],[8],[1,[28,\"t\",[\"modalFeedback.send\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-feedback/template.hbs"
    }
  });

  _exports.default = _default;
});