define("ui/components/modal-kubeconfig/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/J9cY+O6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"step\"]],1],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[10,\"class\",\"icon icon-spinner icon-spin\"],[8],[9],[0,\" \"],[1,[28,\"t\",[\"k8sPage.configFile.generating\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[24,[\"step\"]],2],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"k8sPage.configFile.usage.helpText\"],[[\"htmlSafe\"],[true]]],false],[9],[0,\"\\n    \"],[1,[28,\"code-block\",null,[[\"language\",\"code\"],[\"yaml\",[24,[\"kubeconfig\"]]]]],false],[0,\"\\n    \"],[1,[28,\"copy-to-clipboard\",null,[[\"tooltipText\",\"buttonText\",\"clipboardText\",\"class\"],[\"\",\"copyToClipboard.tooltip\",[24,[\"kubeconfig\"]],\"with-clip\"]]],false],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"mt-10 mb-0\"],[8],[1,[28,\"t\",[\"k8sPage.configFile.usage.downloadText\"],[[\"downloadUrl\",\"htmlSafe\"],[[24,[\"downloadUrl\"]],true]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"footer-actions\"],[8],[0,\"\\n  \"],[7,\"button\",false],[12,\"class\",\"btn bg-primary close-kubeconfig\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[28,\"t\",[\"generic.closeModal\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-kubeconfig/template.hbs"
    }
  });

  _exports.default = _default;
});