define("ui/models/cloudcredential", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var cloudCredential = _resource.default.extend({
    modal: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    nodeTemplates: (0, _denormalize.hasMany)('id', 'nodetemplate', 'cloudCredentialId', 'globalStore'),
    type: 'cloudCredential',
    canClone: false,
    canEdit: true,
    isAmazon: Ember.computed.notEmpty('amazonec2credentialConfig'),
    isAzure: Ember.computed.notEmpty('azurecredentialConfig'),
    isDo: Ember.computed.notEmpty('digitaloceancredentialConfig'),
    isLinode: Ember.computed.notEmpty('linodecredentialConfig'),
    isOCI: Ember.computed.notEmpty('ocicredentialConfig'),
    isVMware: Ember.computed.notEmpty('vmwarevspherecredentialConfig'),
    displayType: Ember.computed('amazonec2credentialConfig', 'azurecredentialConfig', 'digitaloceancredentialConfig', 'linodecredentialConfig', 'ocicredentialConfig', 'vmwarevspherecredentialConfig', function () {
      var isAmazon = this.isAmazon,
          isAzure = this.isAzure,
          isDo = this.isDo,
          isLinode = this.isLinode,
          isOCI = this.isOCI,
          isVMware = this.isVMware;

      if (isAmazon) {
        return 'Amazon';
      } else if (isAzure) {
        return 'Azure';
      } else if (isDo) {
        return 'Digital Ocean';
      } else if (isLinode) {
        return 'Linode';
      } else if (isOCI) {
        return 'OCI';
      } else if (isVMware) {
        return 'VMware vSphere';
      }
    }),
    numberOfNodeTemplateAssociations: Ember.computed('nodeTemplates.[]', function () {
      return Ember.get(this, 'nodeTemplates').length;
    }),
    actions: {
      edit: function edit() {
        this.modal.toggleModal('modal-add-cloud-credential', {
          cloudCredential: this,
          mode: 'edit'
        });
      }
    }
  });

  var _default = cloudCredential;
  _exports.default = _default;
});