define("ui/authenticated/cluster/storage/persistent-volumes/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JPUQ1EQT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header clearfix\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"right-buttons\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-sm bg-default mr-10\"],[12,\"disabled\",[28,\"rbac-prevents\",null,[[\"resource\",\"scope\",\"permission\"],[\"persistentvolume\",\"cluster\",\"create\"]]]],[3,\"action\",[[23,0,[]],\"importYaml\"]],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"nav.containers.importCompose\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"disabled\",\"route\"],[\"btn btn-sm bg-primary\",[28,\"rbac-prevents\",null,[[\"resource\",\"scope\",\"permission\"],[\"persistentvolume\",\"cluster\",\"create\"]]],\"authenticated.cluster.storage.persistent-volumes.new\"]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"persistentVolumePage.addActionLabel\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"persistentVolumePage.header\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"persistent-volume-table\",null,[[\"rows\"],[[24,[\"model\",\"persistentVolumes\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/storage/persistent-volumes/index/template.hbs"
    }
  });

  _exports.default = _default;
});