define("ui/components/modal-delete-group-role-bindings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w0v4yr8A",
    "block": "{\"symbols\":[\"grb\",\"idx\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-header-text\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"modalDeleteGroupRoleBinding.header\"],[[\"groupName\"],[[24,[\"modalService\",\"modalOpts\",\"model\",\"groupPrincipalName\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"horizontal-form container-fluid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-12 text-center\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"mappedGroupRoleBindingNamesIds\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[8],[0,\"\\n          \"],[1,[23,1,[\"groupRoleBindingName\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"footer-actions\"],[8],[0,\"\\n    \"],[5,\"save-cancel\",[],[[\"@createLabel\",\"@save\",\"@cancel\"],[\"modalDeleteGroupRoleBinding.createLabel\",[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"text-center protip mt-10\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"confirmDelete.protip\"],[[\"key\"],[[24,[\"alternateLabel\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-delete-group-role-bindings/template.hbs"
    }
  });

  _exports.default = _default;
});