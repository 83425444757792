define("ui/components/container/form-upgrade/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pYl3y7C4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"accordion-list-item\",null,[[\"title\",\"detail\",\"status\",\"statusClass\",\"expandAll\",\"expandOnInit\",\"expand\",\"everExpanded\"],[[28,\"t\",[\"formUpgrade.title\"],null],[28,\"t\",[\"formUpgrade.detail\"],[[\"appName\"],[[24,[\"settings\",\"appName\"]]]]],[24,[\"status\"]],[24,[\"statusClass\"]],[24,[\"expandAll\"]],[24,[\"expandOnInit\"]],[28,\"action\",[[23,0,[]],[24,[\"expandFn\"]]],null],true]],{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[24,[\"componentName\"]]],[[\"workload\",\"scaleMode\",\"workloadConfig\",\"editing\",\"isUpgrade\"],[[24,[\"workload\"]],[24,[\"scaleMode\"]],[24,[\"workloadConfig\"]],[24,[\"editing\"]],[24,[\"isUpgrade\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/container/form-upgrade/template.hbs"
    }
  });

  _exports.default = _default;
});