define("ui/components/form-access-modes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xyvE1Tu0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[\"formAccessModes.label\"],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"editing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"checkbox\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"accessRWO\"]]]]],false],[0,\"\\n      \"],[1,[28,\"t\",[\"formAccessModes.accessRWO\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"checkbox\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"accessROX\"]]]]],false],[0,\"\\n      \"],[1,[28,\"t\",[\"formAccessModes.accessROX\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"checkbox\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[24,[\"accessRWX\"]]]]],false],[0,\"\\n      \"],[1,[28,\"t\",[\"formAccessModes.accessRWX\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"accessRWO\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"formAccessModes.accessRWO\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"accessROX\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"formAccessModes.accessROX\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"accessRWX\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"formAccessModes.accessRWX\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/form-access-modes/template.hbs"
    }
  });

  _exports.default = _default;
});