define("ui/authenticated/dummy-dev/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HhIK9fbU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-6 box p-0\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col mt-0 mb-0\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"http://placehold.it/120x120\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"here is some info\"],[9],[0,\"\\n        \"],[7,\"hr\",true],[8],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"and some more down here\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-6 box p-0\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col mt-0 mb-0\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"http://placehold.it/120x120\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"here is some info\"],[9],[0,\"\\n        \"],[7,\"hr\",true],[8],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"and some more down here\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/dummy-dev/template.hbs"
    }
  });

  _exports.default = _default;
});