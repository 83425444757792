define("ui/components/namespace-resource-quota/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c7HdtxGn",
    "block": "{\"symbols\":[\"quota\"],\"statements\":[[4,\"if\",[[24,[\"quotaArray\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\",true],[10,\"class\",\"table fixed\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"formResourceQuota.table.type.label\"],null],false],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"formResourceQuota.table.resources.label\"],null],false],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[1,[28,\"t\",[\"formResourceQuota.table.value.label\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"quotaArray\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"namespace-quota-row\",null,[[\"quota\",\"editing\"],[[23,1,[]],[24,[\"editing\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"editing\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"t\",[\"formResourceQuota.table.noData\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/namespace-resource-quota/template.hbs"
    }
  });

  _exports.default = _default;
});