define("ui/volumes/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zGrORc+j",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cru-persistent-volume-claim\",null,[[\"model\",\"persistentVolumes\",\"storageClasses\",\"namespace\",\"mode\"],[[24,[\"model\",\"pvc\"]],[24,[\"model\",\"persistentVolumes\"]],[24,[\"model\",\"storageClasses\"]],[24,[\"model\",\"pvc\",\"namespace\"]],\"view\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/volumes/detail/template.hbs"
    }
  });

  _exports.default = _default;
});