define("ui/authenticated/project/dns/detail/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R/BCacBW",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cru-dns\",null,[[\"model\",\"dnsRecords\",\"workloads\",\"mode\"],[[24,[\"model\",\"record\"]],[24,[\"model\",\"dnsRecords\"]],[24,[\"model\",\"workloads\"]],\"view\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/dns/detail/index/template.hbs"
    }
  });

  _exports.default = _default;
});