define("ui/components/container-metrics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HO7oIpeZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[8],[0,\"\\n  \"],[1,[28,\"metrics-action\",null,[[\"queryAction\",\"allowDetail\",\"state\"],[[28,\"action\",[[23,0,[]],\"query\"],null],false,[24,[\"state\"]]]]],false],[0,\"\\n  \"],[1,[28,\"metrics-graph\",null,[[\"graphs\",\"loading\",\"noGraphs\"],[[24,[\"graphs\"]],[24,[\"state\",\"loading\"]],[24,[\"state\",\"noGraphs\"]]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/container-metrics/template.hbs"
    }
  });

  _exports.default = _default;
});