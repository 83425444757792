define("ui/authenticated/cluster/storage/classes/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wsH4uxe6",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cru-storage-class\",null,[[\"mode\",\"model\",\"done\",\"cancel\"],[\"new\",[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"back\"],null],[28,\"action\",[[23,0,[]],\"back\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/storage/classes/new/template.hbs"
    }
  });

  _exports.default = _default;
});