define("ui/components/project-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ow9Nqo6L",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"valign\",\"middle\"],[10,\"class\",\"row-check\"],[10,\"style\",\"padding-top: 2px;\"],[8],[0,\"\\n  \"],[1,[28,\"check-box\",null,[[\"nodeId\"],[[24,[\"model\",\"id\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[24,[\"dt\",\"state\"]]]]],[10,\"class\",\"state\"],[8],[0,\"\\n  \"],[1,[28,\"badge-state\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[28,\"t\",[\"projects.table.project.label\"],null],\":\"]]],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"clip project-link\",\"authenticated.project\",[24,[\"model\",\"id\"]]]],{\"statements\":[[0,\"    \"],[1,[24,[\"model\",\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[28,\"t\",[\"projects.table.created.label\"],null],\":\"]]],[8],[0,\"\\n  \"],[1,[28,\"date-calendar\",[[24,[\"model\",\"created\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[11,\"data-title\",[29,[[28,\"t\",[\"generic.actions\"],null],\":\"]]],[10,\"class\",\"actions\"],[8],[0,\"\\n  \"],[1,[28,\"action-menu\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/project-row/template.hbs"
    }
  });

  _exports.default = _default;
});