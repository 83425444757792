define("ui/templates/-ns-quota-progressbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "INO0N01s",
    "block": "{\"symbols\":[\"tt\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table fixed tooltip-table\"],[10,\"style\",\"max-width: 300px;\"],[8],[0,\"\\n  \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"label\"]],false],[0,\":\"],[9],[0,\"\\n        \"],[7,\"td\",true],[8],[1,[23,1,[\"value\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/-ns-quota-progressbar.hbs"
    }
  });

  _exports.default = _default;
});