define("ui/components/form-node-affinity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UbMlY45N",
    "block": "{\"symbols\":[\"rule\"],\"statements\":[[4,\"each\",[[24,[\"rules\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"form-node-selector\",null,[[\"removeRule\",\"rule\",\"editing\"],[[28,\"action\",[[23,0,[]],\"removeRule\"],null],[23,1,[]],[24,[\"editing\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"editing\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-center text-muted pt-20 pb-20\"],[8],[1,[28,\"t\",[\"formNodeAffinity.noRules\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"editing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn bg-link icon-btn pull-left\"],[3,\"action\",[[23,0,[]],\"addRule\"]],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"darken\"],[8],[7,\"i\",true],[10,\"class\",\"icon icon-plus text-small\"],[8],[9],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[1,[28,\"t\",[\"formNodeAffinity.addRuleLabel\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/form-node-affinity/template.hbs"
    }
  });

  _exports.default = _default;
});