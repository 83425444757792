define("ui/catalog-tab/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y7D2mHeP",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"catalog-index\",null,[[\"application\",\"category\",\"categoryAction\",\"launch\",\"launchRoute\",\"model\",\"istio\",\"parentRoute\",\"refresh\",\"showHeader\"],[[24,[\"application\"]],[24,[\"category\"]],[28,\"action\",[[23,0,[]],\"categoryAction\"],null],[28,\"action\",[[23,0,[]],\"launch\"],null],[24,[\"launchRoute\"]],[24,[\"model\"]],[24,[\"istio\"]],[24,[\"parentRoute\"]],[28,\"action\",[[23,0,[]],\"refresh\"],null],true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/catalog-tab/index/template.hbs"
    }
  });

  _exports.default = _default;
});