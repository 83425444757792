define("ui/models/dockercredential", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PRESETS_BY_NAME = _exports.PRESETS = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var PRESETS = _defineProperty({
    'index.docker.io': 'dockerhub',
    'quay.io': 'quay'
  }, window.location.host, window.location.host);

  _exports.PRESETS = PRESETS;
  var PRESETS_BY_NAME = {};
  _exports.PRESETS_BY_NAME = PRESETS_BY_NAME;
  Object.keys(PRESETS).forEach(function (key) {
    PRESETS_BY_NAME[PRESETS[key]] = key;
  });

  var DockerCredential = _resource.default.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    state: 'active',
    canClone: true,
    firstRegistry: Ember.computed.alias('asArray.firstObject'),
    registryCount: Ember.computed.alias('asArray.length'),
    asArray: Ember.computed('registries', function () {
      var all = Ember.get(this, 'registries') || {};
      var reg, address, preset;
      return Object.keys(all).map(function (key) {
        address = key.toLowerCase();
        reg = all[key];
        preset = PRESETS[address] || 'custom';
        return {
          address: address,
          auth: reg.auth,
          username: reg.username,
          password: reg.password,
          preset: preset
        };
      });
    }),
    searchAddresses: Ember.computed('asArray.[].address', function () {
      return Ember.get(this, 'asArray').map(function (x) {
        return Ember.get(x, 'address');
      }).sort().uniq();
    }),
    searchUsernames: Ember.computed('asArray.[].username', function () {
      return Ember.get(this, 'asArray').map(function (x) {
        return Ember.get(x, 'username');
      }).sort().uniq();
    }),
    displayAddress: Ember.computed('intl.locale', 'registryCount', 'firstRegistry.address', function () {
      var address = Ember.get(this, 'firstRegistry.address');

      if (Ember.get(this, 'registryCount') > 1) {
        return 'cruRegistry.multiple';
      } else if (address === window.location.host) {
        return address;
      } else if (PRESETS[address]) {
        return Ember.get(this, 'intl').t("cruRegistry.address.".concat(PRESETS[address]));
      } else {
        return address;
      }
    }),
    displayUsername: Ember.computed('registryCount', 'firstRegistry.username', function () {
      var intl = Ember.get(this, 'intl');
      var username = Ember.get(this, 'firstRegistry.username');

      if (Ember.get(this, 'registryCount') > 1) {
        return intl.t('cruRegistry.multiple');
      } else {
        return username;
      }
    }),
    actions: {
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.registries.new', {
          queryParams: {
            id: Ember.get(this, 'id'),
            type: Ember.get(this, 'type')
          }
        });
      }
    }
  });

  var _default = DockerCredential;
  _exports.default = _default;
});