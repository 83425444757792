define("ui/components/volume-source/source-photon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+2NRdJWI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[\"cruPersistentVolume.photonPersistentDisk.pdID.label\"],null],false],[9],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[24,[\"editing\"]],[24,[\"config\",\"pdID\"]]]],{\"statements\":[[0,\"      \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[24,[\"config\",\"pdID\"]],\"form-control\",[28,\"t\",[\"cruPersistentVolume.photonPersistentDisk.pdID.placeholder\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[\"cruPersistentVolume.photonPersistentDisk.fsType.label\"],null],false],[9],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[24,[\"editing\"]],[24,[\"config\",\"fsType\"]]]],{\"statements\":[[0,\"      \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[24,[\"config\",\"fsType\"]],\"form-control\",[28,\"t\",[\"cruPersistentVolume.photonPersistentDisk.fsType.placeholder\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/volume-source/source-photon/template.hbs"
    }
  });

  _exports.default = _default;
});