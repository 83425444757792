define("ui/components/modal-new-volume/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P9dARxwd",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cru-volume\",null,[[\"model\",\"mode\",\"actuallySave\",\"doSave\",\"cancel\"],[[24,[\"model\"]],\"new\",false,[28,\"action\",[[23,0,[]],\"doSave\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-new-volume/template.hbs"
    }
  });

  _exports.default = _default;
});