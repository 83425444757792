define("ui/authenticated/cluster/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WxDAgq6d",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"model\",\"isActive\"]]],null,{\"statements\":[[4,\"banner-message\",null,[[\"color\"],[[28,\"concat\",[[24,[\"model\",\"stateBackground\"]],\"  m-0\"],null]]],{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[1,[28,\"t\",[[28,\"if\",[[24,[\"model\",\"isReady\"]],\"clusterDashboard.notActive\",\"clusterDashboard.notReady\"],null]],[[\"state\",\"htmlSafe\"],[[24,[\"model\",\"displayState\"]],true]]],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"showTransitioningMessage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[11,\"class\",[29,[[24,[\"model\",\"stateColor\"]]]]],[8],[1,[28,\"uc-first\",[[24,[\"model\",\"transitioningMessage\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/template.hbs"
    }
  });

  _exports.default = _default;
});