define("ui/ingresses/run/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MnAsqVmH",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"new-edit-ingress\",null,[[\"cancel\",\"certificates\",\"done\",\"editing\",\"existing\",\"ingress\",\"namespacedCertificates\"],[[28,\"action\",[[23,0,[]],\"cancel\"],null],[24,[\"model\",\"certificates\"]],[28,\"action\",[[23,0,[]],\"done\"],null],true,[24,[\"model\",\"existing\"]],[24,[\"model\",\"ingress\"]],[24,[\"model\",\"namespacedcertificates\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/ingresses/run/template.hbs"
    }
  });

  _exports.default = _default;
});