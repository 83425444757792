define("ui/templates/-add-cluster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wlcIZfMr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[1,[28,\"t\",[\"clustersPage.addPage.host.clusterName\"],null],false],[1,[22,\"field-required\"],false],[9],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"value\"],[\"text\",[24,[\"newHost\",\"user\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[1,[28,\"t\",[\"clustersPage.addPage.host.hostName\"],null],false],[1,[22,\"field-required\"],false],[9],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"value\"],[\"text\",[24,[\"newHost\",\"advertisedHostname\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pt-10 pb-15\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[1,[28,\"t\",[\"clustersPage.addPage.host.ssh\"],null],false],[1,[22,\"field-required\"],false],[9],[0,\"\\n    \"],[1,[28,\"textarea\",null,[[\"value\",\"classNames\",\"rows\"],[[24,[\"newHost\",\"ssh\"]],\"form-control no-resize\",\"6\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/-add-cluster.hbs"
    }
  });

  _exports.default = _default;
});