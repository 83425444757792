define("ui/components/modal-edit-apikey/component", ["exports", "shared/mixins/new-or-edit", "shared/mixins/modal-base", "ui/components/modal-edit-apikey/template", "moment", "jquery", "ui/utils/constants"], function (_exports, _newOrEdit, _modalBase, _template, _moment, _jquery, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ttlUnits = ['minutes', 'hours', 'days', 'years'];

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    endpointService: Ember.inject.service('endpoint'),
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal', 'alert'],
    model: null,
    clone: null,
    justCreated: false,
    expire: 'never',
    complexExpire: 'max',
    ttlUnit: 'minutes',
    customTTL: '0',
    ttlUnits: ttlUnits,
    originalModel: Ember.computed.alias('modalService.modalOpts'),
    displayEndpoint: Ember.computed.alias('endpointService.api.display.current'),
    linkEndpoint: Ember.computed.alias('endpointService.api.auth.current'),
    showSimpleExpire: Ember.computed.not('authTokenHasMaxTTL'),
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.setProperties(this, {
        clone: Ember.get(this, 'originalModel').clone(),
        model: Ember.get(this, 'originalModel').clone(),
        justCreated: false
      });
      this.expireChanged();
      this.complexExpireChanged();
    },
    didInsertElement: function didInsertElement() {
      setTimeout(function () {
        (0, _jquery.default)('TEXTAREA')[0].focus();
      }, 250);
    },
    expireChanged: Ember.observer('expire', 'customTTLDuration', function () {
      if (!Ember.get(this, 'showSimpleExpire')) {
        return;
      }

      var expire = Ember.get(this, 'expire');
      var isCustom = expire === 'custom';
      var duration = isCustom ? Ember.get(this, 'customTTLDuration') : _moment.default.duration(1, expire);
      Ember.set(this, 'model.ttl', duration.asMilliseconds());
    }),
    complexExpireChanged: Ember.observer('complexExpire', 'maxTTLDuration', 'customTTLDuration', function () {
      if (Ember.get(this, 'showSimpleExpire')) {
        return;
      }

      var complexExpire = Ember.get(this, 'complexExpire');
      var maxTTLDuration = Ember.get(this, 'maxTTLDuration');
      var customTTLDuration = Ember.get(this, 'customTTLDuration');
      var duration = complexExpire === 'max' ? maxTTLDuration : customTTLDuration;
      console.log(complexExpire, maxTTLDuration, customTTLDuration, duration.asMilliseconds());
      Ember.set(this, 'model.ttl', duration.asMilliseconds());
    }),
    ttlUnitChanged: Ember.observer('ttlUnit', function () {
      Ember.set(this, 'customTTL', 0);
    }),
    customTTLDuration: Ember.computed('customTTL', 'ttlUnit', function () {
      var customTTL = Number.parseFloat(Ember.get(this, 'customTTL'));
      var ttlUnit = Ember.get(this, 'ttlUnit');
      return _moment.default.duration(customTTL, ttlUnit);
    }),
    authTokenMaxTTL: Ember.computed("settings.".concat(_constants.default.SETTING.AUTH_TOKEN_MAX_TTL_MINUTES), function () {
      var maxTTL = Ember.get(this, "settings.".concat(_constants.default.SETTING.AUTH_TOKEN_MAX_TTL_MINUTES)) || '0';
      return Number.parseFloat(maxTTL);
    }),
    authTokenHasMaxTTL: Ember.computed('authTokenMaxTTL', function () {
      return Ember.get(this, 'authTokenMaxTTL') > 0;
    }),
    maxTTLDuration: Ember.computed('authTokenMaxTTL', function () {
      var maxTTLInMinutes = Ember.get(this, 'authTokenMaxTTL');
      return _moment.default.duration(maxTTLInMinutes, 'minutes');
    }),
    maxTTLBestUnit: Ember.computed('maxTTLDuration', function () {
      var duration = Ember.get(this, 'maxTTLDuration');
      return this.getBestTimeUnit(duration);
    }),
    friendlyMaxTTL: Ember.computed('maxTTLDuration', 'maxTTLBestUnit', function () {
      var intl = Ember.get(this, 'intl');
      var duration = Ember.get(this, 'maxTTLDuration');
      var unit = Ember.get(this, 'maxTTLBestUnit');
      var count = roundDown(duration.as(unit), 2);
      return intl.t("editApiKey.ttl.max.unit.".concat(unit), {
        count: count
      });
    }),
    editing: Ember.computed('clone.id', function () {
      return !!Ember.get(this, 'clone.id');
    }),
    displayPassword: Ember.computed('clone.token', 'clone.name', function () {
      var prefix = Ember.get(this, 'clone.name');
      var token = Ember.get(this, 'clone.token');

      if (!token || !prefix) {
        return null;
      }

      var parts = token.split(':');

      if (parts.length === 2 && parts[0] === prefix) {
        return parts[1];
      }

      return null;
    }),
    ttlCustomMax: Ember.computed('authTokenHasMaxTTL', 'ttlUnit', 'maxTTLDuration', function () {
      if (!Ember.get(this, 'authTokenHasMaxTTL')) {
        return;
      }

      var unit = Ember.get(this, 'ttlUnit');
      var duration = Ember.get(this, 'maxTTLDuration');
      return roundDown(duration.as(unit), 2);
    }),
    ttlUnitOptions: Ember.computed('maxTTLBestUnit', function () {
      var unit = Ember.get(this, 'maxTTLBestUnit');
      var indexOfUnit = ttlUnits.indexOf(unit);
      return ttlUnits.slice(0, indexOfUnit + 1);
    }),
    allClusters: Ember.computed('scope.allClusters.@each.{id}', function () {
      var allClusters = Ember.get(this, 'scope.allClusters');
      return allClusters.map(function (c) {
        return {
          label: "".concat(Ember.get(c, 'displayName'), " ( ").concat(Ember.get(c, 'id'), " )"),
          value: Ember.get(c, 'id')
        };
      }).sortBy('displayName');
    }),
    getBestTimeUnit: function getBestTimeUnit(duration) {
      var reversed = [].concat(ttlUnits).reverse();
      var unit = reversed.find(function (unit) {
        return duration.as(unit) >= 1;
      });
      return unit || reversed[0];
    },
    doneSaving: function doneSaving(neu) {
      if (Ember.get(this, 'editing')) {
        this.send('cancel');
      } else {
        Ember.setProperties(this, {
          justCreated: true,
          clone: neu.clone()
        });
      }
    }
  });

  _exports.default = _default;

  function roundDown(value, digits) {
    var factor = 10 * digits;
    return Math.floor(value * factor) / factor;
  }
});