define("ui/components/container/form-upgrade-replication-controller/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7bOugq3n",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"box\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"acc-label\"],[8],[1,[28,\"t\",[\"formUpgrade.strategy.minReadySeconds.label\"],null],false],[9],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[24,[\"editing\"]],[24,[\"workloadConfig\",\"minReadySeconds\"]]]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n          \"],[1,[28,\"input\",null,[[\"classNames\",\"value\"],[\"form-control\",[24,[\"workloadConfig\",\"minReadySeconds\"]]]]],false],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"input-group-addon bg-default\"],[8],[1,[28,\"t\",[\"generic.seconds\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"help-block\"],[8],[1,[28,\"t\",[\"formUpgrade.strategy.minReadySeconds.detail\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/container/form-upgrade-replication-controller/template.hbs"
    }
  });

  _exports.default = _default;
});