define("ui/models/configmap", ["exports", "@rancher/ember-api-store/utils/denormalize", "@rancher/ember-api-store/models/resource"], function (_exports, _denormalize, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    state: 'active',
    canClone: true,
    canHaveLabels: true,
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    firstKey: Ember.computed.alias('keys.firstObject'),
    workloads: Ember.computed('namespace.workloads.@each.volumes', 'namespace.workloads.@each.containers', function () {
      var _this = this;

      return (Ember.get(this, 'namespace.workloads') || []).filter(function (workload) {
        var volume = (Ember.get(workload, 'volumes') || []).find(function (volume) {
          return Ember.get(volume, 'configMap.name') === Ember.get(_this, 'name');
        });
        var env = (Ember.get(workload, 'containers') || []).find(function (container) {
          return (Ember.get(container, 'environmentFrom') || []).find(function (env) {
            return Ember.get(env, 'source') === 'configMap' && Ember.get(env, 'sourceName') === Ember.get(_this, 'name');
          });
        });
        return volume || env;
      });
    }),
    keys: Ember.computed('data', 'binaryData', function () {
      var _this$data = this.data,
          data = _this$data === void 0 ? {} : _this$data,
          _this$binaryData = this.binaryData,
          binaryData = _this$binaryData === void 0 ? {} : _this$binaryData;
      var dataKeys = Object.keys(data);
      var binaryDataKeys = Object.keys(binaryData);

      if (Ember.isEmpty(dataKeys)) {
        return binaryDataKeys;
      } else {
        return dataKeys;
      }
    }),
    configData: Ember.computed('data', 'binaryData', function () {
      var _this$data2 = this.data,
          data = _this$data2 === void 0 ? {} : _this$data2,
          _this$binaryData2 = this.binaryData,
          binaryData = _this$binaryData2 === void 0 ? {} : _this$binaryData2;
      return Object.assign({}, data, binaryData);
    }),
    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.config-maps.detail.edit', Ember.get(this, 'id'));
      },
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.config-maps.new', Ember.get(this, 'projectId'), {
          queryParams: {
            id: Ember.get(this, 'id')
          }
        });
      }
    }
  });

  _exports.default = _default;
});