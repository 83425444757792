define("ui/templates/tooltip-container-dot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eDhDgMNi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"tooltip-content-inner tooltip-dot\"],[8],[0,\"\\n  \"],[1,[28,\"action-menu\",null,[[\"model\",\"showPrimary\",\"inTooltip\",\"class\"],[[24,[\"model\"]],false,true,\"pull-right tooltip-more-actions\"]]],false],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"display-name\"],[8],[7,\"a\",true],[11,\"href\",[29,[[28,\"href-to\",[\"pod\",[24,[\"model\",\"id\"]]],null]]]],[8],[1,[22,\"displayName\"],false],[9],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"pull-right\"],[8],[1,[28,\"badge-state\",null,[[\"classNames\",\"model\"],[\"btn-xs\",[24,[\"model\"]]]]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"display-ip pull-left pr-5\"],[8],[1,[28,\"format-ip\",[[24,[\"model\",\"displayIp\"]]],null],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"node\",\"displayName\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"display-ip pull-left pr-5\"],[8],[0,\"\\n        \"],[1,[24,[\"model\",\"node\",\"displayName\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"model\",\"showTransitioningMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"text-small bg-body p-5 mt-5 force-wrap \",[24,[\"model\",\"stateColor\"]]]]],[8],[1,[28,\"uc-first\",[[24,[\"model\",\"transitioningMessage\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/tooltip-container-dot.hbs"
    }
  });

  _exports.default = _default;
});