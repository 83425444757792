define("ui/components/modal-drain-node/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qLrdhCR4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-header-text\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"resources\",\"length\"]],1],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"drainNode.titleOne\"],[[\"name\"],[[24,[\"resources\",\"firstObject\",\"displayName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"drainNode.titleMultiple\"],[[\"count\"],[[24,[\"resources\",\"length\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"section\",true],[10,\"class\",\"p-20 pt-0\"],[8],[0,\"\\n  \"],[1,[28,\"drain-node\",null,[[\"selection\"],[[24,[\"selection\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"save-cancel\",null,[[\"editing\",\"editLabel\",\"save\",\"cancel\"],[true,\"drainNode.action\",[28,\"action\",[[23,0,[]],\"drain\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-drain-node/template.hbs"
    }
  });

  _exports.default = _default;
});