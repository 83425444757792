define("ui/authenticated/project/container-log/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BsyvmWbM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mr-20 ml-20 mt-20 mb-20\"],[8],[0,\"\\n  \"],[1,[28,\"container-logs\",null,[[\"instance\",\"containerName\",\"showProtip\",\"logHeight\",\"dismiss\"],[[24,[\"model\",\"pod\"]],[24,[\"model\",\"containerName\"]],false,185,[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/container-log/template.hbs"
    }
  });

  _exports.default = _default;
});