define("ui/components/cluster/cis/scan/detail/table-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0pIe9K3V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"td\",true],[10,\"valign\",\"middle\"],[10,\"class\",\"row-check\"],[10,\"style\",\"padding-top: 2px;\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasExpandableContent\"]]],null,{\"statements\":[[0,\"            \"],[7,\"i\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"icon icon-chevron-right eased text-small text-muted \",[28,\"if\",[[24,[\"expanded\"]],\"icon-rotate-90\"],null]]]],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[7,\"span\",true],[10,\"class\",\"visually-hidden\"],[8],[0,\"Open accordion\"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"td\",true],[10,\"width\",\"100\"],[10,\"class\",\"pr-30\"],[8],[5,\"badge-state\",[[12,\"class\",\"text-center full-width\"]],[[\"@model\"],[[22,\"badgeState\"]]]],[9],[0,\"\\n    \"],[7,\"td\",true],[8],[1,[24,[\"model\",\"id\"]],false],[9],[0,\"\\n    \"],[7,\"td\",true],[10,\"class\",\"vertical-top pt-15\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-small\"],[8],[1,[24,[\"model\",\"description\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"remediation\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"text-small text-error mt-5\"],[8],[1,[28,\"t\",[\"cis.scan.detail.table.nodesTable.row.remediation\"],[[\"remediation\"],[[24,[\"model\",\"remediation\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"td\",true],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"expanded\"]],[24,[\"hasExpandableContent\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[10,\"colspan\",\"5\"],[10,\"class\",\"p-20\"],[8],[0,\"\\n            \"],[5,\"cluster/cis/scan/detail/nodes-table\",[],[[\"@nodes\"],[[24,[\"model\",\"nodes\"]]]]],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/cluster/cis/scan/detail/table-row/template.hbs"
    }
  });

  _exports.default = _default;
});