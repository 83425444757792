define("ui/components/modal-edit-node-pool/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "taEOIMmv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"editNodePool.title\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"section\",true],[10,\"class\",\"horizontal-form container-fluid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row inline-form\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col span-12\"],[8],[0,\"\\n      \"],[1,[28,\"form-node-taints\",null,[[\"model\",\"nodeTemplateTaints\",\"expandOnInit\"],[[24,[\"model\"]],[24,[\"model\",\"nodeTemplate\",\"nodeTaints\"]],true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"top-errors\",null,[[\"errors\"],[[24,[\"errors\"]]]]],false],[0,\"\\n\"],[1,[28,\"save-cancel\",null,[[\"editing\",\"save\",\"cancel\"],[true,[28,\"action\",[[23,0,[]],\"save\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-edit-node-pool/template.hbs"
    }
  });

  _exports.default = _default;
});