define("ui/components/modal-wechat/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MHFHaxpC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"settings\",\"isRancher\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"logo-text\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"parachute\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"align\",\"center\"],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"modalWechat.title\"],null],false],[9],[0,\"\\n  \"],[7,\"img\",true],[10,\"height\",\"128\"],[10,\"width\",\"128\"],[11,\"src\",[29,[[24,[\"app\",\"baseAssets\"]],\"assets/images/wechat-qr-code.jpg\"]]],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"footer-actions\"],[8],[0,\"\\n\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[28,\"t\",[\"generic.closeModal\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-wechat/template.hbs"
    }
  });

  _exports.default = _default;
});