define("ui/authenticated/cluster/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cSPlNqbp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"clustersPage.editClusterName\"],[[\"name\"],[[24,[\"model\",\"originalCluster\",\"displayName\"]]]]],false],[0,\" (\"],[1,[28,\"driver-name\",[[24,[\"provider\"]]],null],false],[0,\")\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"cru-cluster\",[],[[\"@applyClusterTemplate\",\"@clusterTemplateRevisionId\",\"@model\",\"@initialProvider\",\"@provider\",\"@mode\",\"@close\"],[[28,\"not\",[[28,\"is-empty\",[[24,[\"clusterTemplateRevision\"]]],null]],null],[28,\"mut\",[[24,[\"clusterTemplateRevision\"]]],null],[22,\"model\"],[24,[\"cluster\",\"provider\"]],[22,\"provider\"],\"edit\",[28,\"action\",[[23,0,[]],\"close\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/edit/template.hbs"
    }
  });

  _exports.default = _default;
});