define("ui/components/container/form-upgrade-stateful-set/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yDjZaQjh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col span-6\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"radio mb-10\"],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[1,[28,\"radio-button\",null,[[\"disabled\",\"selection\",\"value\"],[[28,\"not\",[[24,[\"editing\"]]],null],[24,[\"workloadConfig\",\"strategy\"]],\"RollingUpdate\"]]],false],[0,\" \"],[1,[28,\"t\",[\"formUpgrade.strategy.rollingUpdate\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"radio mb-10\"],[8],[0,\"\\n      \"],[7,\"label\",true],[8],[1,[28,\"radio-button\",null,[[\"disabled\",\"selection\",\"value\"],[[28,\"not\",[[24,[\"editing\"]]],null],[24,[\"workloadConfig\",\"strategy\"]],\"OnDelete\"]]],false],[0,\" \"],[1,[28,\"t\",[\"formUpgrade.strategy.onDelete\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/container/form-upgrade-stateful-set/template.hbs"
    }
  });

  _exports.default = _default;
});