define("ui/components/security-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gwbwId8O",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\",true],[10,\"class\",\"header has-tabs clearfix p-0\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"tab-nav\"],[8],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"authenticated.project.secrets\"]],{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"secretsPage.index.header\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"authenticated.project.certificates\"]],{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"certificatesPage.index.header\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"li\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\"],[\"authenticated.project.registries\"]],{\"statements\":[[0,\"        \"],[1,[28,\"t\",[\"registriesPage.index.header\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"right-buttons\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/security-header/template.hbs"
    }
  });

  _exports.default = _default;
});