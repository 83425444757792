define("ui/authenticated/project/registries/detail/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0+lvQYoJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"cru-registry\",null,[[\"mode\",\"model\"],[\"view\",[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/registries/detail/index/template.hbs"
    }
  });

  _exports.default = _default;
});