define("ui/authenticated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HTkqGv+v",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"isPopup\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"page-header\",null,[[\"pageScope\",\"goToPrevious\"],[[24,[\"pageScope\"]],\"goToPrevious\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"main\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"unless\",[[24,[\"isPopup\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"page-footer\",null,[[\"showAbout\"],[\"showAbout\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/template.hbs"
    }
  });

  _exports.default = _default;
});