define("ui/components/modal-drain-node/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-drain-node/template", "async"], function (_exports, _modalBase, _template, _async) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend(_modalBase.default, {
    growl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['medium-modal'],
    selection: {},
    resources: Ember.computed.alias('modalService.modalOpts.resources'),
    init: function init() {
      this._super.apply(this, arguments);

      this.selection = {};
    },
    actions: {
      drain: function drain() {
        var nodeDrainInput = _objectSpread({}, Ember.get(this, 'selection'));

        var resources = Ember.get(this, 'resources').slice();
        (0, _async.eachLimit)(resources, 5, function (resource, cb) {
          if (!resource) {
            return cb();
          }

          resource.doAction('drain', nodeDrainInput).finally(cb);
        });
        this.send('cancel');
      }
    }
  });

  _exports.default = _default;
});